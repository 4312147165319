/* ##### Power Grotesk ##### */

@font-face {
  font-family: 'PowerGrotesk';
  src: url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-UltraLight.woff2'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-UltraLight.woff'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-UltraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'PowerGrotesk';
  src: url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Light.woff2'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Light.woff'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'PowerGrotesk';
  src: url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Regular.woff2'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Regular.woff'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'PowerGrotesk';
  src: url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Medium.woff2'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Medium.woff'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'PowerGrotesk';
  src: url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Bold.woff2'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Bold.woff'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'PowerGrotesk';
  src: url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-UltraBold.woff2'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-UltraBold.woff'),
    url('./assets/fonts/power-grotesk/WOFF/PowerGrotesk-UltraBold.ttf');
  font-weight: 800;
}

/* ##### Codec Warm ##### */

@font-face {
  font-family: 'CodecWarm';
  src: url('./assets/fonts/codec-warm/Woff2/Codec-Warm-Thin.woff2'),
    url('./assets/fonts/codec-warm/Woff/Codec-Warm-Thin.woff'),
    url('./assets/fonts/codec-warm/Ttf/Codec-Warm-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'CodecWarm';
  src: url('./assets/fonts/codec-warm/Woff2/Codec-Warm-Extralight.woff2'),
    url('./assets/fonts/codec-warm/Woff/Codec-Warm-Extralight.woff'),
    url('./assets/fonts/codec-warm/Ttf/Codec-Warm-Extralight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'CodecWarm';
  src: url('./assets/fonts/codec-warm/Woff2/Codec-Warm-Light.woff2'),
    url('./assets/fonts/codec-warm/Woff/Codec-Warm-Light.woff'),
    url('./assets/fonts/codec-warm/Ttf/Codec-Warm-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'CodecWarm';
  src: url('./assets/fonts/codec-warm/Woff2/Codec-Warm-Regular.woff2'),
    url('./assets/fonts/codec-warm/Woff/Codec-Warm-Regular.woff'),
    url('./assets/fonts/codec-warm/Ttf/Codec-Warm-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'CodecWarm';
  src: url('./assets/fonts/codec-warm/Woff2/Codec-Warm-Bold.woff2'),
    url('./assets/fonts/codec-warm/Woff/Codec-Warm-Bold.woff'),
    url('./assets/fonts/codec-warm/Ttf/Codec-Warm-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'CodecWarm';
  src: url('./assets/fonts/codec-warm/Woff2/Codec-Warm-Extrabold.woff2'),
    url('./assets/fonts/codec-warm/Woff/Codec-Warm-Extrabold.woff'),
    url('./assets/fonts/codec-warm/Ttf/Codec-Warm-Extrabold.ttf');
  font-weight: 800;
}
