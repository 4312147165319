@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.css';

body {
  @apply font-sans text-sm m-0 p-0 box-border text-gray-50 bg-menu-background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  @apply text-secondary-dark;
}

h1 {
  @apply font-heading text-2xl font-extrabold;
}

h2 {
  @apply text-sm font-bold leading-[140%];
}

input,
textarea {
  @apply min-h-[1.5rem] focus:outline-none;
}

.form-input:has(input:-webkit-autofill) {
  @apply bg-autofill;
}
button:focus {
  @apply outline-none;
}

input:disabled,
button:disabled {
  @apply bg-gray-25;
}

.rc-slider-disabled {
  background-color: transparent !important;
}

.dashboard-editor-gridster-wrapper > .gridster {
  padding: 1px !important;
}

.Toastify__toast-body {
  white-space: pre-line;
}
tfoot *,
::before,
::after {
  border-style: none !important;
}

#building-sankey .highcharts-scrollbar {
  display: none !important;
}
